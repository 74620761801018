<template>
  <!-- Footer -->
  <footer>
    <div class="container">
      <hr />
      <div
        class="row py-3 justify-content-center align-items-center text-center"
      >
        <div class="col-lg-12">
          <small
            >© {{ new Date().getFullYear("YYYY") }} - Pedro Rodrigues -
            {{ $store.state.biography.copyright }}</small
          >
        </div>
        <div class="col-lg-12 py-3">
          <small class="label-footer">{{
            $store.state.biography.footerInfo
          }}</small>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterComponent",
  props: {
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.label-footer {
  font-weight: 400;
}

.vue {
  height: 28px;
  width: 28px;
  padding: 5px;
}
</style>