<template>
  <!-- Start -->
  <section id="start" class="">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-6 col-md-6" data-aos="fade">
          <p class="small-title" data-aos="fade" data-aos-delay="100">
            {{ $store.state.biography.introTitle }}
          </p>
          <p class="title-name" data-aos="fade-up" data-aos-delay="300">
            {{ $store.state.biography.introSubtitle }}
          </p>
          <p class="types" data-aos="fade" data-aos-delay="1500">
            {{ $store.state.biography.introContent }}
          </p>
          <div class="box"></div>
        </div>
        <div class="col-lg-5 col-md-6">
          <img data-aos="fade" data-aos-delay="100" src="@/assets/pedro.jpg"
            :alt="$store.state.biography.imageDescription" class="img-fluid my-photo" />
          <div class="box mb-5 ml-auto"></div>
        </div>
        <div class="col-lg-12 text-center">
          <a href="#about" class="scroll-btn">
            <img src="@/assets/scroll.gif" alt="" class="scroll-icon" srcset="">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "StartSection",
  data() {
    return {};
  },
  props: {
    biography: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.scroll-icon {
  text-align: center;
  bottom: 20px;
  mix-blend-mode: multiply;
  left: 45%;
  width: 200px;
}

.small-title {
  color: #555555;
  font-size: 1.4em;
  margin: 0;
}

.title-name {
  font-size: 3em;
  margin: 0;
  font-weight: 400;
  line-height: normal;
}

.types {
  font-weight: 100;
  font-size: 1.6em;
  margin: 0;
  color: rgb(84, 84, 84);
}

@media (max-width: 768px) {
  .title-name {
    font-size: calc(3em - 40%);
  }

  .types {
    font-size: calc(1.6em - 40%);
  }

  .my-photo {
    display: block;
    margin: 0 auto;
    padding-top: 50px;
  }
}

.my-photo {
  filter: grayscale(100%);
  width: 100%;
  padding-top: 50px;
}

#content {
  background: rgb(241, 241, 241);
  height: 95vh;
}

#start {
  background: rgb(241, 241, 241);
  color: #131313;
  display: flex;
  align-items: center;
  padding-top: 100px;
  height: 100%;
}

.box {
  background-color: #0f0f0f;
  height: 30px;
  width: 30px;
  margin-top: 30px;
  margin-left: 2px;
  opacity: 0;
  animation-name: fade;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 100%;
  }

  100% {
    opacity: 0;
  }
}
</style>