<template>
  <!-- Projects -->
  <section id="projects" class="py-5">
    <div class="container text-center">
      <div class="row py-5 portfolio">
        <div class="col-lg-12">
          <h2 class="title__section" data-aos="fade-up" data-aos-delay="100">
            {{ $store.state.biography.projectsTitle }}
          </h2>
        </div>
      </div>
      <div class="projects-cards">
        <template v-for="(project, i) in $store.state.projects" :key="i">
          <CardProject
            :project="project"
            data-aos="fade-up"
            data-aos-delay="100"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import CardProject from "@/components/CardProject.vue";

export default {
  name: "ProjectsSection",
  components: { CardProject },
  data() {
    return {
      projects: this.projectsList,
    };
  },
  methods: {},
};
</script>

<style scoped>
.projects-cards {
  display: flex;
  position: relative;
  gap: 26px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
