<template>
  <section id="404">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-12 align-items-center">
          <h1 class="not-found__title">Página não encontrada</h1>
          <h3 class="not-found__subtitle">
            A página que você está tentando acessar não existe.
          </h3>
          <a class="btn-primario" @click="$router.push('/')">Voltar</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import mixpanel from "mixpanel-browser";

export default {
  name: "NotFoundPage",
  props: {
    biography: {
      type: Object,
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    mixpanel.track("pageview", {
      page: this.$route.fullPath,
    });
  },
};
</script>

<style scoped>
section {
  height: 80vh;
  display: flex;
  align-items: center;
}

h1.not-found__title {
  font-weight: 700;
  font-size: 2em;
}

.not-found__subtitle {
  margin: 20px 0;
}

/*  ===== BOTÃO ====  */
.btn-primario {
  border: 1px solid #1c1c1c;
  border-radius: 0px;
  padding: 15px 40px;
  margin: 20px;
  display: inline-flex;
}

a.btn-primario:hover {
  background-color: #efefef;
  transform: scale(1.01);
  cursor: pointer;
}
</style>