import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import AOS from 'aos'
import mixpanel from 'mixpanel-browser'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import store from './store'
import VueGtag from 'vue-gtag'


Sentry.init({
  App,
  dsn: "https://35932455a40b40c29fb80662d6cb319b@o4503966572019712.ingest.sentry.io/4503966574444544",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "prodrigues.com.br", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


mixpanel.init('5e22e693eb99c5f8e6dda0a05fa80a77', { debug: true })
createApp(App)
  .use(store)
  .use(router)
  .use(mixpanel)
  .use(VueGtag, { config: { id: '330575671' } })
  .use(AOS.init({
    duration: "1500",
    mirror: false,
    once: true,
  })).mount('#app')

