<template>
  <Loading :loading="loading"></Loading>
  <div data-aos="fade" id="header" v-if="!loading">
    <div class="header-image">
      <img :src="this.project.projectThumbnail" alt="" srcset="" />
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="header-project">
            <h1 data-aos="fade" class="project-name">
              {{ this.project.name }}
            </h1>
            <h4 data-aos="fade" class="client-name">
              {{ this.project.client }}
            </h4>
          </div>
          <hr class="mb-5" />
        </div>
        <div class="col-lg-12">
          <span v-html="this.project.description"></span>
          <div class="stack">
            <h4 class="stack-title">Skills</h4>
            <div class="tags">
              <template v-for="(stack, i) in this.project.stacks" :key="i">
                <span class="tag">{{ stack.description }}</span>
              </template>
            </div>
          </div>
          <div class="actions">
            <div class="project-link">
              <a
                v-show="this.project.projectLink"
                :href="this.project.projectLink"
                class="btn btn-primario"
              >
                {{ this.project.projectButton }}</a
              >
            </div>
            <div class="icons">
              <a
                v-show="this.project.behanceLink"
                :href="this.project.behanceLink"
                class="p-1"
              >
                <i class="footer-icon fab fa-behance" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div v-if="this.project.uxCase" data-aos="fade" data-aos-delay="200">
        <template v-for="(section, i) in this.project.uxSteps" :key="i">
          <UXSection :projectColor="project.projectColor" :section="section" />
        </template>
        <template v-for="(info, i) in this.project.additionalInfo" :key="i">
          <div class="row justify-content-around py-5">
            <div class="col-lg-5 text-center">
              <h3 class="phrase">{{ info.phrase }}</h3>
              <p class="author">{{ info.author }}</p>
            </div>
          </div>
          <div class="row justify-content-around align-items-center py-5">
            <div class="col-lg-5 text-center">
              <img
                v-if="info.image"
                :src="require(`@/assets/projects/ux/${info.image}`)"
                class="img-fluid"
                alt=""
              />
              <iframe
                v-if="info.videoUrl"
                :src="info.videoUrl"
                width="100%"
                height="300px"
                frameborder="0"
              ></iframe>
            </div>
            <div class="col-lg-6">
              <p class="description">{{ info.description }}</p>
            </div>
          </div>
        </template>
      </div>

      <div v-else>
        <div class="row pb-5 align-items-center justify-content-center">
          <template v-for="(photo, i) in this.project.photos" :key="i">
            <div class="col-lg-12">
              <img
                :src="photo.url"
                class="img-fluid"
                :alt="photo.description"
              />
            </div>
          </template>
        </div>
      </div>

      <ContactSection />
    </div>
  </div>
</template>

<script>
// Constants
import ContactSection from "@/components/sections/ContactSection.vue";
import UXSection from "@/components/sections/UXSection.vue";
import Loading from "@/components/Loading";

// Model
import ProjectsModel from "@/model/projects-model";
import utilStorage from "@/utils/storage";

// Api
import apiProjects from "@/api/projects-api";
import mixpanel from "mixpanel-browser";

export default {
  name: "ProjectView",
  components: {
    ContactSection,
    UXSection,
    Loading,
  },
  data() {
    return {
      project: new ProjectsModel(),
      loading: true,
    };
  },
  computed: {},
  mounted() {
    this.getProjectsBySlug();
    this.$gtag.pageview(this.$route);
    mixpanel.track("pageview", {
      page: this.$route.fullPath,
      project: this.$route.params.slug,
    });
  },
  methods: {
    getProjectsBySlug() {
      let lang = utilStorage.getUserLang().substring(0, 2);
      let slug = this.$route.params.slug;
      apiProjects
        .getBySlug(lang, slug)
        .then((response) => {
          this.project = new ProjectsModel(response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.phrase {
  font-size: 2em;
}

hr {
  color: #b5b5b5;
}

.author {
  color: #696969;
}

.description {
  font-size: 1.1em;
  line-height: 2.5em;
  padding: 30px 0;
}

.thumb-photos {
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.8s;
}

.thumb-photos:hover {
  filter: grayscale(100%);
  transform: scale(0.95);
}

.project-name {
  color: #000;
  font-weight: 400;
}

.client-name {
  color: #848484;
  font-weight: 100;
}

.header-image {
  width: 100%;
  display: flex;
}

.header-image > img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.stack-title {
  font-size: 1.3em;
}

.stack {
  display: flex;
  gap: 10px;
  padding: 20px 0;
  flex-direction: column;
  font-weight: 400;
}

.tags {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag {
  background-color: #e6e6e6;
  padding: 5px 15px;
  font-size: 0.8em;
  height: 30px;
  border-radius: 8px;
}

.header-project {
  padding: 40px 0;
}

.header-title > h4 {
  color: #696969;
  font-size: 1.2em;
}

.actions {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}
</style>
