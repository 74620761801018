import api from "./api";

function getAll(lang) {
  return new Promise((resolve, reject) => {
    return api.get(`/biography/${lang}`)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export default {
  getAll,
}