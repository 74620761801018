<template>
  <div v-if="loading" class="loading">
    <img class="spinner" src="@/assets/loading.gif" />
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  data() {
    return {};
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.loading {
  background-color: #000;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  width: 100%;
  top: 0;
}
</style>