import BiographyModel from "@/model/biography-model";
import ProjectsModel from "@/model/projects-model";
import { createStore } from "vuex";

export default createStore({
  state: {
    projects: [],
    biography: [],
  },
  mutations: {
    createProjects(state, projects) {
      state.projects = projects.map((p) => new ProjectsModel(p))
    },
    createBiography(state, biography) {
      state.biography = new BiographyModel(biography)
    },
    setLang(state, lang) {
      state.lang = lang
    },
    setLoading(state, loading) {
      state.loading = loading
    }
  },
  getters: {
    getProjects(state) {
      return state.projects
    }
  }
})