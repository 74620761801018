<template>
  <div data-tilt class="projects">
    <!-- {{ this.project }} -->
    <a @click="openProject(project.slug)">
      <img :src="project.thumbnail" class="thumb-projects" />
      <div class="overlay"></div>
    </a>
  </div>
</template>

<script>
import mixpanel from "mixpanel-browser";

export default {
  name: "CardProject",
  data() {
    return {};
  },
  props: {
    project: {
      type: Object,
    },
  },
  mounted() {},
  methods: {
    openProject(slug) {
      this.$gtag.event("view_project", {
        event_category: this.project.uxProject,
      });
      mixpanel.track("click", {
        nameOfButton: "See Project",
        nameOfProject: slug,
      });
      this.$router.push({
        name: "ProjectView",
        params: {
          slug: slug,
        },
      });
    },
  },
};
</script>

<style >
.projects {
  display: flex;
  width: 350px;
  height: 300px;
}

.thumb-projects {
  width: 350px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.8s;
}

.projects:hover .overlay {
  opacity: 1;
}

.projects:hover .title-projects {
  opacity: 1;
}

.projects:hover .thumb-projects {
  filter: grayscale(100%);
  transform: scale(0.95);
}

.contact {
  text-align: right;
}
</style>
