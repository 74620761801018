<template class="py-5">
  <h1 class="step-id" v-if="section.step">
    {{ section.step }}
  </h1>
  <p class="step-description">
    {{ section.description }}
  </p>
  <div class="row align-items-center" v-if="section.uxNumbers != []">
    <template v-for="(item, i) in section.uxNumbers" :key="i">
      <div class="col-lg-4 py-5">
        <NumberComponent :value="item.title" :description="item.description" />
      </div>
    </template>
  </div>

  <!-- Text on left -->
  <template v-for="(content, i) in section.uxContent" :key="i">
    <div
      :class="content.textPosition == 'right' ? 'row-reverse' : ''"
      class="row py-5 justify-content-between align-items-center"
    >
      <div
        :class="
          !!content.image && content.textPosition != 'center'
            ? 'col-lg-6'
            : 'col-lg-12'
        "
      >
        <h1 class="title" v-if="content.title" :style="{ color: projectColor }">
          {{ content.title }}
        </h1>
        <p class="description" v-html="content.description" v-if="content.description">
        </p>
        <span v-if="content.topics" class="py-5">
          <template v-for="(topic, i) in content.topics" :key="i">
            <ul class="topic">
              <li :class="`topic-item-${topic.icon}` ">
                {{ topic.description }}
              </li>
            </ul>
          </template>
        </span>
      </div>
      <div
        :class="content.textPosition == 'center' ? 'col-lg-12' : 'col-lg-6'"
        class="col-lg-5"
        v-if="!!content.image"
      >
        <img
          :src="require(`@/assets/projects/ux/${content.image}`)"
          class="img-fluid"
          :alt="content.imageDescription"
        />
      </div>
    </div>
  </template>
</template>
<script>
import NumberComponent from "../NumberComponent.vue";

export default {
  name: "UXSection",
  components: {
    NumberComponent,
  },
  data() {
    return {
      contents: this.section.content,
    };
  },
  props: {
    section: {
      type: Object,
    },
    textPosition: {
      type: String,
    },
    projectColor: {
      type: String,
    },
  },
  mounted() {},
  computed: {
    hasImage() {
      return this.section.image == "" ? false : true;
    },
  },
};
</script>

<style scoped>
.numbers-wrapper {
  display: flex;
}

h1.title {
  font-size: 2.5em;
}

.topic-item-check::before {
  content: url("@/assets/check.png");
  padding: 5px;
}
.topic-item-question::before {
  content: url("@/assets/question.png");
  padding: 5px;
}

.topic {
  list-style: none;
  font-size: 1.1em;
  padding: 10px 0;
  align-items: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

p.description {
  font-size: 1.1em;
  line-height: 2.5em;
  padding: 30px 0;
}

.step-id {
  font-size: 5.3em;
  font-weight: 900;
  font-family: "Rubik", sans-serif;
}

.step-description {
  font-size: 1.4em;
  padding-top: 0;
  margin-top: 0;
  line-height: 0;
  color: #9b9b9b;
  font-weight: 100;
}
b{
  font-weight: 600!important;
}
</style>