function getUserLang() {
  return localStorage.getItem('user-lang');
}

function setlang(value) {
  return localStorage.setItem('lang', value)
}

function setUserLang(value) {
  return localStorage.setItem('user-lang', value)
}
function setProject(project) {
  return localStorage.setItem('projects', JSON.stringify(project))
}

function getProjects() {
  return localStorage.getItem('projects')
}

export default {
  setlang,
  getUserLang,
  setUserLang,
  getProjects,
  setProject
}