<template>
  <!-- Contact -->
  <section id="contact" class="py-5">
    <div class="container">
      <div class="row justify-content-between aligm align-items-center text-right">
        <div class="col-lg-5 contact">
          <div class="box"></div>
          <h2 class="lets_work">{{ $store.state.biography.contactTitle }}</h2>
        </div>
        <div class="col-lg-5 contact">
          <h4 class="mail">{{ $store.state.biography.contactEmail }}</h4>
          <h4 class="phone pb-5">
            {{ $store.state.biography.contactPhone }}
          </h4>
          <a class="footer-link" :href="$store.state.biography.contactLinkedIn" target="_blank">
            <p class="footer-text align-items-md-center">
              <i class="fab 2x fa-linkedin-in" aria-hidden="true"></i> /
              Linkedin
            </p>
          </a>
          <a class="footer-link" :href="$store.state.biography.contactBehance" target="_blank">
            <p class="footer-text align-items-md-center">
              <i class="fab 2x fa-behance" aria-hidden="true"></i> / Behance
            </p>
          </a>
          <a class="footer-link" :href="$store.state.biography.contactMedium" target="_blank">
            <p class="footer-text align-items-md-center">
              <i class="fab 2x fa-medium" aria-hidden="true"></i> / Medium
            </p>
          </a>
          <a class="footer-link" :href="$store.state.biography.contactGithub" target="_blank">
            <p class="footer-text align-items-md-center">
              <i class="fab 2x fa-github" aria-hidden="true"></i> / Github
            </p>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionContact",
  data() {
    return {};
  },
};
</script>

<style scoped>
.box {
  background-color: #000;
  width: 25px;
  height: 25px;
  margin-bottom: 30px;
}

.lets_work {
  font-size: 2.5em;
  font-weight: 300;
  text-align: left;
  color: #000;
  padding: 30px 0;
}

.mail,
.phone {
  font-weight: 400;
}
</style>
