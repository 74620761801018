import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView";
import ProjectView from "@/views/ProjectView.vue";
import AllProjectsView from "@/views/AllProjectsView.vue";

import NotFoundPage from '@/views/NotFoundView.vue'

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,

  },
  {
    path: "/projects",
    name: "AllProjects",
    children: [
      {
        path: "",
        name: "ProjectView",
        component: AllProjectsView,
      },
      {
        path: ":slug",
        name: "ProjectView",
        component: ProjectView,
      },
    ]
  },
  {
    path: '/404',
    name: 'NotFoundPage',
    component: NotFoundPage,
    meta: { reload: true },

  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router;
