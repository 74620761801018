<template>
  <!-- About Me -->
  <section id="about" class="py-5">
    <div class="container py-2">
      <div class="row">
        <div class="col-lg-8 about">
          <div class="box__about"></div>
          <h2 class="title__section" data-aos="fade-up" data-aos-delay="100">
            {{ $store.state.biography.aboutTitle }}
          </h2>
          <p
            data-aos="fade"
            data-aos-delay="600"
            class="text__about"
            v-html="$store.state.biography.aboutContent"
          ></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutComponent",
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style lang="css" scoped>
.text__about {
  font-size: 1.2em;
  line-height: 2.5em;
  padding: 30px 0;
  font-weight: 200;
}
.title__section{
  color: #fafafa;
}
#about {
  background-color: #070707;
  color: #fafafa;
}
</style>
