<template>
  <Loading :loading="loading" v-if="loading"></Loading>
  <HeaderComponent v-if="!loading" />
  <main v-if="!loading">
    <router-view />
    <FooterComponent />
  </main>
</template>
<script>
// Component
import HeaderComponent from "@/components/Header";
import FooterComponent from "@/components/Footer";
import Loading from "@/components/Loading";
// Utils
import utilStorage from "@/utils/storage";

// Api
import apiBiography from "@/api/biography-api";
import apiProjects from "@/api/projects-api";

// Model
import BiographyModel from "@/model/biography-model";
import ProjectsModel from "@/model/projects-model";

export default {
  name: "app",
  components: {
    HeaderComponent,
    FooterComponent,
    Loading,
  },
  data() {
    return { loading: true };
  },
  watch: {
    "$store.state.lang"() {
      this.setup(this.$store.state.lang);
    },
  },
  mounted() {
    this.verifyUserLangage();
    this.$gtag.pageview(this.$route);
  },
  methods: {
    sort() {
      this.projects = this.projects.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    verifyUserLangage() {
      let browser = navigator.language || navigator.browserLanguage;
      let user = utilStorage.getUserLang();
      !user ? this.setup(browser) : this.setup(user);
    },

    setup(lang) {
      let language = lang.substring(0, 2);
      utilStorage.setUserLang(lang);
      this.getBio(language);
      this.getProjects(language);
    },

    getBio(lang) {
      apiBiography
        .getAll(lang)
        .then((response) => {
          this.bio = new BiographyModel(response.data[0]);
          this.$store.commit("createBiography", this.bio);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjects(lang) {
      apiProjects
        .getAll(lang)
        .then((response) => {
          this.projects = response.data.map((p) => new ProjectsModel(p));
          this.projects = this.projects.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          this.$store.commit("createProjects", this.projects);
          utilStorage.setProject(this.projects);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="css">
#app {
  height: 100vh;
  position: relative;
  display: block;
  font-family: "Rubik", sans-serif;
}

.ligth {
  background-color: #fff;
}

body {
  /* background: rgba(0, 0, 0, 0.95) 100%;
  color: white; */
  font-family: "Rubik", sans-serif;
  font-weight: 100;
  font-style: normal;
  overflow-y: scroll;
}

::selection {
  background-color: #000;
  /* background-blend-mode: exclusion; */
  color: #000;
}

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #696969;
  cursor: url("@/assets/click.png"), pointer !important;
}

/* ----- Texts ----- */

h1.title {
  font-size: 4em;
  font-weight: 500;
  color: #fff;
}

h2.title__section {
  font-size: 3em;
  font-weight: 300;
  text-align: left;
  color: #000;
}

/* SELECAO */

::selection {
  background-color: rgb(56, 56, 56);
  color: rgb(210, 210, 210);
}

a:hover,
button:hover {
  cursor: url("@/assets/click.png"), pointer !important;
}

/* Scroll bar */

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000;
  background-blend-mode: exclusion;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  background-blend-mode: exclusion;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(158, 158, 158);
}

/* Button */
.btn {
  height: 38px;
}

.btn-primario {
  background-color: #000;
  color: #fff;
  padding: 8px 30px;
}

.btn-primario:hover {
  color: #dbdbdb;
  background-color: rgb(49, 49, 49);
}
</style>
