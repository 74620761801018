<template>
  <div class="number-card">
    <h1 class="number">{{ value }}</h1>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'NumberComponent',
  data() {
    return {
    }
  }
  ,
  props: {
    value: {
      type: String
    },
    description: {
      type: String
    }
  }
}
</script>

<style scoped>
.number-card {
  display: flex;
  flex-direction: column;
}

.number {
  font-size: 4.5em;
  font-family: 'Rubik', sans-serif;
  color: red;
  font-weight: 600;
}

.description {
  color: #696969;
  font-size: 1.3em;
}

.number-card {
  text-align: center;
}
</style>