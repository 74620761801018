// import HeaderModel from '@/model/header-model'
export default class BiographyModel {

  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.headerMenu = obj.headerMenu
    this.introTitle = obj.introTitle;
    this.introSubtitle = obj.introSubtitle;
    this.introContent = obj.introContent;
    this.imageDescription = obj.imageDescription;
    this.projectsTitle = obj.projectsTitle;
    this.projectsSubtitle = obj.projectsSubtitle;
    this.aboutTitle = obj.aboutTitle;
    this.aboutSubtitle = obj.aboutSubtitle;
    this.aboutContent = obj.aboutContent;
    this.contactTitle = obj.contactTitle;
    this.contactPhone = obj.contactPhone;
    this.contactEmail = obj.contactEmail;
    this.contactLinkedIn = obj.contactLinkedIn;
    this.contactMedium = obj.contactMedium;
    this.contactBehance = obj.contactBehance;
    this.contactGithub = obj.contactGithub;
    this.footerInfo = obj.footerInfo;
    this.copyright = obj.copyright;
  }

}