<template v-if="!loading">
  <header id="home">
    <nav class="navbar navbar-expand-lg fixed-top">
      <!-- Menu principal -->
      <div v-if="!isBack" class="container-fluid">
        <a class="header__logo" href="./">
          <img src="@/assets/logo.svg" alt="Logotipo" />
        </a>
        <button
          v-if="!isBack"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#menu"
          aria-controls="menu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src="@/assets/menu.svg" alt="" />
        </button>
        <div
          v-if="!isBack"
          class="collapse navbar-collapse justify-content-end"
          id="menu"
        >
          <ul class="navbar-nav">
            <li
              class="nav-item"
              v-for="menu in $store.state.biography.headerMenu"
              :key="menu.id"
            >
              <a
                :href="menu.route"
                :target="menu.newPage ? '_blank' : ''"
                class="nav-link"
                >{{ menu.description }}</a
              >
            </li>
          </ul>
          <ul class="navbar-nav lang-nav">
            <li
              class="nav-item"
              :class="this.lang == 'pt-BR' ? 'lang__item--active' : ''"
            >
              <a class="nav-link" @click="switchLang('pt-BR')">PT</a>
            </li>
            <li
              class="nav-item"
              :class="this.lang == 'en-US' ? 'lang__item--active' : ''"
            >
              <a class="nav-link" @click="switchLang('en-US')">EN</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Menu voltar -->
      <div v-else class="container-fluid">
        <div class="go-back">
          <a @click="goBack()">
            <img src="@/assets/arrow-left.svg" />
          </a>
        </div>

        <div class="logo">
          <a class="header__logo" href="./">
            <img src="@/assets/logo.svg" alt="Logotipo" />
          </a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import utilStorage from "@/utils/storage";
import mixpanel from "mixpanel-browser";

export default {
  name: "HeaderComponent",
  data() {
    return {
      menu: false,
      lang: utilStorage.getUserLang(),
    };
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
  mounted() {
    this.lang = utilStorage.getUserLang();
    // console.log("Lang: ", this.lang);
  },
  methods: {
    switchLang(lang) {
      this.lang = lang;
      this.$store.commit("setLang", this.lang);
      mixpanel.track("click", {
        nameOfButton: "Switch Lang",
        switchLang: lang,
        page: this.$route.fullPath,
      });
    },
    goBack() {
      mixpanel.track("click", {
        nameOfButton: "Go Back",
        page: this.$route.fullPath,
      });
      this.$router.push({
        name: "HomeView",
      });
    },
  },
  computed: {
    isBack() {
      return this.$route.name == "ProjectView" ? true : false;
    },
  },
};
</script>

<style scoped lang="css">
#menu {
  gap: 50px;
}
.lang-nav {
  padding: 0 10px;
}
.lang__item--active {
  border-bottom: 1px solid white;
  transition-property: fade;
  animation: fade 1s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
button {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px;
  display: flex;
}

button:active,
button:focus {
  border: none;
  box-shadow: none;
}

header {
  background: #00000056;
  color: #fff;
  /* padding: 15px 20px; */
  display: block;
  position: sticky;
  width: 100%;
  z-index: 999;
  gap: 20px;
}

.go-back {
  height: 50px;
  display: flex;
  align-items: center;
}

.header__container {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__mobile {
  display: none;
}

.mobile__menu {
  display: block;
  position: absolute;
  background-color: #0000004f;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 80px;
  left: 0;
  z-index: 9999;
}

@media (max-width: 800px) {
  .header__container {
    flex-direction: column;
  }

  .header__items {
    gap: 20px;
    font-size: calc(1.2em - 10%);
  }
}
@media (max-width: 990px) {
  .lang__item--active {
    width: 200px;
    border-left: 1px solid white;
    border-bottom: none;
  }
  .lang-nav {
    padding-top: 40px;
  }
}

a.header__logo > img {
  width: 200px;
}

.header_links {
  display: flex;
  gap: 25px;
  padding: 10px;
}

.header__items {
  text-align: center;
  font-size: 1.2em;
  color: rgb(206, 206, 206);
  cursor: pointer;
  text-decoration: none;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.nav-item > a:hover {
  color: rgb(103, 103, 103);
}

.nav-item > a {
  /* cursor: url("@/assets/click.png"), pointer !important; */
  color: rgb(206, 206, 206);
}

li.nav-item {
  padding: 0px 10px;
  cursor: url("@/assets/click.png"), pointer !important;
}

nav {
  background-color: #000000f0;
  filter: blur(1);
}
</style>
