<template>
  <Loading :loading="loading"></Loading>
  <StartSection v-if="!loading" />
  <AboutSection v-if="!loading" />
  <ProjectsSection v-if="!loading" />
  <ContactSection v-if="!loading" />
</template>
<script>
// Components
import AboutSection from "@/components/sections/AboutSection.vue";
import StartSection from "@/components/sections/StartSection.vue";
import ProjectsSection from "@/components/sections/ProjectsSection.vue";
import ContactSection from "@/components/sections/ContactSection.vue";
import Loading from "@/components/Loading";
import mixpanel from "mixpanel-browser";

export default {
  name: "HomeView",
  components: {
    AboutSection,
    StartSection,
    ProjectsSection,
    ContactSection,
    Loading,
  },
  data() {
    return {};
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
  created() {},
  mounted() {
    this.$gtag.pageview(this.$route);
    mixpanel.track("pageview", {
      page: this.$route.fullPath,
    });
  },
};
</script>

<style>
</style>
