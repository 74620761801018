// import PhotosModel from "./photos-model";
// import AdditionalInfoModel from "./additional-info-model";
// import UxStepsModel from "./uxSteps-model";

export default class ProjectsModel {

  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.slug = obj.slug;
    this.client = obj.client;
    this.name = obj.name;
    this.projectDate = obj.projectDate;
    this.thumbnail = obj.thumbnail;
    this.projectThumbnail = obj.projectThumbnail;
    this.imageDescription = obj.imageDescription;
    this.description = obj.description;
    this.projectLink = obj.projectLink;
    this.behanceLink = obj.behanceLink;
    this.projectButton = obj.projectButton;
    this.uxCase = obj.uxCase;
    this.stacks = obj.stacks;
    this.photos = obj.photos;
    this.uxSteps = obj.uxSteps;
    this.projectColor = obj.projectColor;
    this.additionalInfo = obj.additionalInfo;
    this.createdAt = obj.createdAt;
  }

}